import { z } from "zod";
import { requiredError } from "./errors";

export const userFormSchema = {
  name: z.string(requiredError).describe("Nome"),
  surname: z.string(requiredError).describe("Cognome"),
  email: z.string(requiredError).trim().email("Email non valida").describe("Email"),
  phone: z.string(requiredError).describe("Telefono").optional().nullable(),
};

export const userFieldConfigs = {
  name: {
    inputProps: { autocomplete: "given-name" },
  },
  surname: {
    inputProps: { autocomplete: "family-name" },
  },
  email: {
    inputProps: { autocomplete: "email" },
  },
  phone: {
    inputProps: { autocomplete: "tel" },
  },
};
